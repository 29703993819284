<template>
  <app-form @submit="onSubmit">
    <div
      class="flex h-screen w-screen items-center justify-center bg-primary-950"
    >
      <app-panel class="w-full max-w-[400px] p-6" title="Welcome">
        <template #header>
          <app-image
            alt="Pollen logo"
            class="m-auto"
            :height="40"
            src="/images/logo_ico_color_transparent.svg"
            :width="40"
          />
        </template>
        <div class="grid gap-6">
          <app-form-input-text
            v-model="form.userName"
            label="User name"
            required
          />

          <app-form-input-text-obfuscated
            v-model="form.password"
            label="Password"
            required
          />
        </div>

        <template #footer>
          <app-button class="w-full" color="dark" type="submit">
            Enter
          </app-button>
        </template>
      </app-panel>
    </div>
  </app-form>
</template>

<script lang="ts" setup>
import { RESTRICTED_ACCESS_CREDENTIALS_COOKIE_NAME } from "~/core/restricted-access/restricted-access.model";

defineRouteRules(
  // @ts-expect-error missing security rules
  {
    index: false,
  },
);

definePageMeta({
  layout: false,
});

useSeoMeta({
  robots: "noindex",
});

const form = reactive({
  userName: "",
  password: "",
});

const onSubmit = () => {
  useCookie(RESTRICTED_ACCESS_CREDENTIALS_COOKIE_NAME, {
    // Cookie valid for 1 year
    maxAge: 3600 * 24 * 365,
  }).value = `${form.userName}_${form.password}`;

  return navigateTo("/");
};
</script>
